import { Component, Prop, Vue } from 'vue-property-decorator'

//models
import { ListMenuOptionModel } from '@/molds/ListMenuOptionModel'

@Component({})
export default class ListMenuOption extends Vue {
  @Prop({ required: true }) listMenuOption!: ListMenuOptionModel;
  @Prop() itemSelect!: any;
  @Prop() itemSelectFildName!: string;

  clickRow(item: object){
    this.$emit('clickRow', item);
  }

	verifySelect(item: any): string {
		if(this.itemSelectFildName && this.itemSelect && this.itemSelect[this.itemSelectFildName] == item[this.itemSelectFildName] ){
			return 'item-selected'
		} return ''
	}

  getFieldValue(item: any, listMenuOption: any, indexColumn: any){
    return item[listMenuOption.columns[indexColumn].field]
  }
}