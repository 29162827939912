import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class ListGroups extends Vue {
  @Prop() data!: Array<object>;

  clickRowGroup(item: object){
    this.$emit('clickRowGroup', item);
  }
}
