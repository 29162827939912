import { Component, Prop, Vue } from 'vue-property-decorator'

//components
import Navbar from '@/components/navbar/Navbar.vue'
import ListPanel from '@/components/listPanel/ListPanel.vue'
import ListGroups from '@/components/listGroups/ListGroups.vue'
import ListMenuOption from '@/components/listMenuOption/ListMenuOption.vue'
import PrivateContent from '@/components/privateContent/PrivateContent.vue'
import HistoryProcess from '@/components/historyProcess/HistoryProcess.vue'

const namespace = 'group';
//Vuex
import { Action, Getter } from 'vuex-class';

//classes
import { Alert } from '@/services/notification'

//models
import { Group } from '@/store/modules/group/group';
import { Form, Input } from '@/components/wrappers/molds/Form';

//wrappers
import { 
	Container, 
	Box, 
	WTabs,
	WColumns,
	WColumn,
  WButton,
  WHr,
	WForm,
	WSubtitle,
	Loader,
} from '@/components/wrappers';

@Component({
	components:{
		Navbar,
		Container,
		Box,
		ListPanel,
		WTabs,
		WColumns,
    WColumn,
    ListMenuOption,
    WButton,
    WHr,
    ListGroups,
    PrivateContent,
    HistoryProcess,
		WForm,
		WSubtitle,
		Loader
	}
})
export default class GroupClass extends Vue {

	@Action('fetchGroup', { namespace }) fetchGroup: any;
	@Action('updateGroup', { namespace }) updateGroup: any;

	@Getter('group', { namespace }) group!: Group;
	@Getter('groups', { namespace }) users!: any;
	@Getter('loader', { namespace }) isLoading!: boolean;
	@Getter('error', { namespace }) error!: any;

	alert = new Alert(this.$store)
	idGroup!: string | undefined
	isPage = false

	mounted(): void {
		document.title = 'Dados do grupo'
		this.init()
	}

	init(): void {
		if(!isNaN(parseInt(this.$route.params.id))){
			this.idGroup = this.$route.params.id
			this.isPage = true
			this.dispatchGroup()
		}else{
			this.$router.push("/404")
		}
	}

	async dispatchGroup(): Promise<void> {
		await this.fetchGroup(this.idGroup)
		if(this.error){
			this.alert.apiMessage("Não foi possível encontrar o grupo.", this.error, "danger")
		}
	}

	async dispatchUpdateGroup(data: Group): Promise<void> {
		const onUpdate = { idGroup: this.idGroup, group: data }		
		
		await this.updateGroup(onUpdate)

		if(this.error){
			this.alert.apiMessage("Não foi possível atualizar o grupo.", this.error, "danger")
		}else{
			this.alert.success('Grupo atualizado com sucesso!')
		}
	}

	get formGroup(): Form {
		return {
			flex: false,
			indent: true,
			inputsPadding: '10px',
			grid: this.grid
		}
	}

	get grid(): Input[][] {
		return [
			[
				{
					fieldName: "name",
					label: "Nome",
					type: "text",
					maxlength: 50,
					placeholder:"nome",
					required: true,
					value: this.group.name
				},
				{
					fieldName: "shortName",
					label: "Abreviação",
					type: "text",
					maxlength: 50,
					placeholder:"Abreviação",
					required: true,
					value: this.group.shortName
				},
			],
			[
				{
					fieldName: "status",
					label: "Status",
					type: "select",
					maxlength: 50,
					required: true,
					value: this.group.status, 
					options:[
						{
							label: "Desativado",
							value: "0"
						},
						{
							label: "Ativo",
							value: "1"
						},
					]
				},
			]
		]
	}

}
